module.exports = Object.freeze({
  //ROOT_API_URL: "http://localhost:8085",
  ROOT_API_URL:
    "https://lph-dplm-test-spring-app-20231124214941.azuremicroservices.io/",
  ADDONS_ENDPOINT: "/admin/addons",
  APPOINTMENTS_ENDPOINT: "/appointments",
  EVENTS_ENDPOINT: "/events",
  FAQS_ENDPOINT: "/faqs",
  INVOICES_ENDPOINT: "/invoices",
  RESERVATIONS_ENDPOINT: "/reservations",
  REVIEWS_ENDPOINT: "/reviews",
  USERS_ENDPOINT: "/users",
  KEYCLOAK_CLIENT_ID: "le-prestige-hall-ui",
});
