export const AppointmentModel = {
	id: "",
	firstName: "",
	lastName: "",
	phone: "",
	email: "",
	dateTime: "",
	raison: "",
	additionalInfo: "",
	status: "",
};

