export const ServiceModel = {
	id: "",
	avalaible: true,
	description: "",
	title: "",
	imageSource: "",
};

export const Services = [
	{
			id: "service_1",
			available: true,
			description: "Prestigious venues and personalized attention for your anniversary celebrations.",
			title: "Anniversaries",
			imageSource: "assets/icons/anniversary_icon.svg",
	},
	{
			id: "service_2",
			available: true,
			description: "Elegant settings for welcoming new beginnings at baby showers.",
			title: "Baby Showers",
			imageSource: "assets/icons/baby_troller_icon.svg",
	},
	{
			id: "service_3",
			available: true,
			description: "Tailored and refined birthday party experiences for lasting memories.",
			title: "Birthday Parties",
			imageSource: "assets/icons/birthday_cake_icon.svg",
	},
	{
			id: "service_4",
			available: true,
			description: "Luxurious bridal shower events in the most elegant of atmospheres.",
			title: "Bridal Showers",
			imageSource: "assets/icons/bridals_rings_icon.svg",
	},
	{
			id: "service_5",
			available: true,
			description: "Professional and upscale conference facilities for impactful gatherings.",
			title: "Conferences",
			imageSource: "assets/icons/conference_icon.svg",
	},
	{
			id: "service_6",
			available: true,
			description: "Celebrate academic success in our sophisticated and stately graduation venues.",
			title: "Graduations",
			imageSource: "assets/icons/graduation_icon.svg",
	},
	{
			id: "service_7",
			available: true,
			description: "Host festive holiday parties in our exclusive and premier event spaces.",
			title: "Holiday Parties",
			imageSource: "assets/icons/holyday_party_icon.svg",
	},
	{
			id: "service_8",
			available: true,
			description: "Conduct your meetings in our distinguished spaces designed for productivity.",
			title: "Meetings",
			imageSource: "assets/icons/meeting_icon.svg",
	},
	{
			id: "service_9",
			available: true,
			description: "Capture the moment with our deluxe photo booth rentals for any upscale event.",
			title: "Photo Booth Rental",
			imageSource: "assets/icons/photo_booth_icon.svg",
	},
	{
			id: "service_10",
			available: true,
			description: "Elevate your seminars with our first-class facilities and services.",
			title: "Seminars",
			imageSource: "assets/icons/seminar_icon.svg",
	},
	{
			id: "service_11",
			available: true,
			description: "Plan your dream wedding in our magnificent and grandiose event halls.",
			title: "Weddings",
			imageSource: "assets/icons/wedding_rings_icon.svg",
	},
	{
			id: "service_12",
			available: true,
			description: "Organize your corporate events at our exclusive venue for a prestigious affair.",
			title: "Work Events",
			imageSource: "assets/icons/event_icon.svg",
	},
	{
			id: "service_13",
			available: true,
			description: "Embrace tradition with elegance in our specially curated event experiences.",
			title: "Traditional Events",
			imageSource: "assets/icons/traditional_icon.svg",
	},
	{
			id: "service_14",
			available: true,
			description: "Whatever the occasion, our luxurious facilities are the perfect backdrop for your event.",
			title: "Other Events",
			imageSource: "assets/icons/more_icon.svg",
	},
];
