export default Object.freeze({
  // ----- Facility Constants -----
  ADDRESS: {
    Street: "740 Lakeview Plaza Blvd",
    City: "Worthington",
    State: "OH",
    ZipCode: "43085",
  },
  CLEANING_FEE: "Cleaning Fee",
  CLEANING_FEES_LARGE_GUESTS_COUNT: 250,
  CLEANING_FEES_SMALL_GUESTS_COUNT: 150,
  CLEANING_FEES_LARGE_GUESTS_COUNT_NAME: "Cleaning Large Guests Count",
  CLEANING_FEES_SMALL_GUESTS_COUNT_NAME: "Cleaning Small Guests Count",
  COMPUTATION_METHOD: { AUTO: "Auto", MANUAL: "Manual" },
  CATEGORY_FACILITY: "Facility",
  EMAILS: {
    billing: "billing@leprestigehall.net",
    customerService: "customerservice@leprestigehall.net",
    info: "info@leprestigehall.net",
    invoices: "invoices@leprestigehall.net",
    support: "support@leprestigehall.net",
  },
  FACILITY_FEE: "Facility Fee",
  INVOICE_STATUS: {
    DUE: "Due",
    OVERDUE: "Overdue",
    PAID: "Paid",
    PARTIALLY_PAID: "Partially Paid",
    PENDING: "Pending",
    WITHDWAWN: "Withdrawn",
  },
  ITEMS_PER_PAGE: 6,
  GENDERS: {
    MALE: "Male",
    FEMALE: "Female",
    NON_BINARY: "Non Binary",
    OTHER: "Other",
  },
  OVERTIME_RATE: 150,
  OVERTIME_HOURLY_RATE_NAME: "Overtime Hourly Rate",
  PHONE: {
    format1: "(614) 316-1430",
    format2: "+1 614 316 1430",
    format3: "16143161430",
    format4: "+16143161430",
  },
  REGULAR_DAYS_RATE: 1500,
  REGULAR_FACILITY_RATE_NAME: "Regular Facility Rate",
  SATURDAY_FACILITY_RATE_NAME: "Saturday Facility Rate",
  SATURDAY_RATE: 2000,
  SEAT_FEE: "Seat Fee",
  SEAT_RATE: 2.5,
  SEAT_RATE_NAME: "Seat Rate",
  STATUS: {
    BOOKED: "Booked",
    CANCELLED: "Cancelled",
    DONE: "Done",
    PASSED: "Passed",
    PENDING: "Pending",
    REQUESTED: "Requested",
    SETTLED: "Settled",
    COMPLETED: "Completed",
    CONFIRMED: "Confirmed",
    IN_PROGRESS: "In Progress",
  },
  STATES: {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  },
  TAXE_RATE: 0.07,
  WEEK_DAYS: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
});
